import React from 'react';
import { PageProps } from 'gatsby';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';

import Layout, { LayoutOptions } from '../../components/Layout/Layout';
import { LocaleNameSpaceKeys } from '../../localization/translations';
import Link from '../../components/Link/Link';
import { getPathFromRouteKey } from '../../shared/utils/getPathFromRouteKey';
import RouteKeys from '../../../config/RouteKeys';
import ExternalLink from '../../components/ExternalLink/ExternalLink';
import CommonData from '../../data/common/common';
import './style.scss';

type Props = PageProps & WithTranslation;

const TermsConditions: React.FC<Props> = (props: Props) => {
  const { t } = props;

  const layoutOptions: LayoutOptions = {
    seo: {
      title: t('seo.title'),
    },
    page: {
      title: t('heading'),
    },
  };

  const PrivacyLink = (plinkProps: any) => (
    <Link to={getPathFromRouteKey(RouteKeys.PRIVACY_STATEMENT)} {...plinkProps} />
  );

  const generateList = (
    list: string[],
    opts: { appendElements: any[]; position?: 'top' } = { appendElements: [], position: 'top' }
  ) => {
    return (
      <ul>
        {opts.position === 'top' && opts.appendElements}
        {list.map((str, i) => {
          return <li key={`${Math.random()}-${i}`}>{str}</li>;
        })}
        {opts.position !== 'top' && opts.appendElements}
      </ul>
    );
  };

  const emails = {
    mortgageServicing: t(`${LocaleNameSpaceKeys.COMMON}:emails.mortgageServicing`),
    privacyOfficer: t(`${LocaleNameSpaceKeys.COMMON}:emails.privacyOfficer`),
    contact: t(`${LocaleNameSpaceKeys.COMMON}:emails.contact`),
    servicing: t(`${LocaleNameSpaceKeys.COMMON}:emails.servicing`),
  };

  return (
    <Layout options={layoutOptions} className="TermsConditions">
      <div className="TermsConditions__generic-gap">
        <h2 className="h3">{t('introduction.heading')}</h2>
        <p>
          <Trans i18nKey="introduction.body" t={t} />
        </p>
      </div>

      <div className="TermsConditions__generic-gap">
        <h2 className="h3">{t('generalDisclaimers.heading')}</h2>
        <p>
          <Trans i18nKey="generalDisclaimers.body" t={t} />
        </p>
      </div>

      <div className="TermsConditions__generic-gap">
        <h2 className="h3">{t('siteAccessAccountSetUpSecurity.heading')}</h2>
        <p>
          <Trans i18nKey="siteAccessAccountSetUpSecurity.body" t={t}>
            <PrivacyLink />
          </Trans>
        </p>
      </div>

      <div className="TermsConditions__generic-gap">
        <h2 className="h3">{t('useOfSites.heading')}</h2>
        <p>{t('useOfSites.firstListBlock.heading')}</p>
        {generateList(
          t('useOfSites.firstListBlock.list', {
            returnObjects: true,
          })
          // {
          //   appendElements: [
          //     <li key="useOfSites-f-item-3">
          //       <Trans i18nKey="useOfSites.firstListBlock.lastItemOfList" t={t}>
          //         <ExternalLink href={CommonData.socialMediaLinks.linkedIn} />
          //         <ExternalLink href={CommonData.socialMediaLinks.facebook} />
          //         <ExternalLink href={CommonData.socialMediaLinks.instagram} />
          //       </Trans>
          //     </li>,
          //   ],
          // }
        )}

        <p>{t('useOfSites.secondListBlock.heading')}</p>
        {generateList(
          t('useOfSites.secondListBlock.list', {
            returnObjects: true,
          }),
          {
            appendElements: [
              <li key="useOfSites-s-item-1">
                <Trans i18nKey="useOfSites.secondListBlock.topItemOfList" t={t}>
                  <PrivacyLink />
                </Trans>
              </li>,
            ],
            position: 'top',
          }
        )}
        <p>{t('useOfSites.footerNote')}</p>
      </div>

      <div className="TermsConditions__generic-gap">
        <h2 className="h3">{t('smsTermsConditions.heading')}</h2>
        {generateList(
          t('smsTermsConditions.list', {
            returnObjects: true,
            tollFreeNumberDepositServices: CommonData.tollFreeCustomerServiceNumber.depositServices,
            contactEmail: emails.contact,
          }),
          {
            appendElements: [
              <li key="smsTermsConditions-f-item-3">
                <Trans i18nKey="smsTermsConditions.bottomItemOfList" t={t}>
                  <PrivacyLink />
                </Trans>
              </li>,
            ],
          }
        )}
      </div>

      <div className="TermsConditions__generic-gap">
        <h2 className="h3">{t('userContent.heading')}</h2>
        <p>
          <Trans i18nKey="userContent.body" t={t}>
            <PrivacyLink />
          </Trans>
        </p>
      </div>

      <div className="TermsConditions__generic-gap">
        <h2 className="h3">{t('siteMonitoringEnforcementSuspensionTermination.heading')}</h2>
        <p>{t('siteMonitoringEnforcementSuspensionTermination.listHeading')}</p>
        {generateList(
          t('siteMonitoringEnforcementSuspensionTermination.list', { returnObjects: true })
        )}
      </div>

      <div className="TermsConditions__generic-gap">
        <h2 className="h3">{t('mobileUse.heading')}</h2>
        <p>{t('mobileUse.body')}</p>
      </div>

      <div className="TermsConditions__generic-gap">
        <h2 className="h3">{t('intellectualProperty.heading')}</h2>
        <p>
          <Trans i18nKey="intellectualProperty.body" t={t} />
        </p>
        <p>{t('intellectualProperty.firstListBlock.heading')}</p>
        {generateList(t('intellectualProperty.firstListBlock.list', { returnObjects: true }))}

        <p>{t('intellectualProperty.secondListBlock.heading')}</p>
        {generateList(t('intellectualProperty.secondListBlock.list', { returnObjects: true }))}
        <p>
          <Trans i18nKey="intellectualProperty.bonafideNote" t={t} />
        </p>
        <p>
          <Trans
            i18nKey="intellectualProperty.footerNote"
            t={t}
            values={{
              privacyOfficerEmail: emails.privacyOfficer,
              torontoPOBoxAddress: t(
                `${LocaleNameSpaceKeys.COMMON}:poAddresses.intellectualProperty`
              ),
            }}
          >
            <ExternalLink
              href={`mailto:${emails.privacyOfficer}`}
              aria-label={`${t(`${LocaleNameSpaceKeys.COMMON}:email`)} ${emails.privacyOfficer}`}
            />
          </Trans>
        </p>
      </div>

      <div className="TermsConditions__generic-gap">
        <h2 className="h3">{t('trademarks.heading')}</h2>
        <p>
          <Trans i18nKey="trademarks.body" t={t} />
        </p>
      </div>

      <div className="TermsConditions__generic-gap">
        <h2 className="h3">{t('privacyConfidentialInformation.heading')}</h2>
        <p>
          <Trans i18nKey="privacyConfidentialInformation.body" t={t}>
            <PrivacyLink />
          </Trans>
        </p>
      </div>

      <div className="TermsConditions__generic-gap">
        <h2 className="h3">{t('thirdPartyWebsites.heading')}</h2>
        <p>
          <Trans i18nKey="thirdPartyWebsites.body" t={t} />
        </p>
      </div>

      <div className="TermsConditions__generic-gap">
        <h2 className="h3">{t('indemnity.heading')}</h2>
        <p>{t('indemnity.body')}</p>
      </div>

      <div className="TermsConditions__generic-gap">
        <h2 className="h3">{t('territory.heading')}</h2>
        <p>{t('territory.body')}</p>
      </div>

      <div className="TermsConditions__generic-gap">
        <h2 className="h3">{t('changesTermsUse.heading')}</h2>
        <p>
          <Trans
            i18nKey="changesTermsUse.body"
            t={t}
            values={{
              mainWebsite: CommonData.mainWebsiteDomain,
            }}
          >
            <ExternalLink href={`https://${CommonData.mainWebsiteDomain}`} />
          </Trans>
        </p>
      </div>

      <div className="TermsConditions__generic-gap">
        <h2 className="h3">{t('miscellaneous.heading')}</h2>
        <p>
          <Trans i18nKey="miscellaneous.body" t={t} />
        </p>
      </div>

      <div className="TermsConditions__generic-gap">
        <h2 className="h3">{t('reportingContact.heading')}</h2>
        <p>
          <Trans
            i18nKey="reportingContact.body"
            t={t}
            values={{
              privacyOfficerEmail: emails.privacyOfficer,
              mortgageServicingEmail: emails.servicing,
              torontoOfficeAddress: t(`${LocaleNameSpaceKeys.COMMON}:officeAddresses.toronto`),
            }}
          >
            <ExternalLink
              href={`mailto:${emails.privacyOfficer}`}
              aria-label={`${t(`${LocaleNameSpaceKeys.COMMON}:email`)} ${emails.privacyOfficer}`}
            />
            <ExternalLink
              href={`mailto:${emails.servicing}`}
              aria-label={`${t(`${LocaleNameSpaceKeys.COMMON}:email`)} ${emails.servicing}`}
            />
          </Trans>
        </p>
      </div>
    </Layout>
  );
};

export default withTranslation(LocaleNameSpaceKeys.TERMS_CONDITIONS)(TermsConditions);
